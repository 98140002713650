import "./App.css";
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';

function App() {

  const projects = [
    {
      name: "Maho Wallet",
      description: "A telegram mini app wallet powered by intents.",
      link: "https://github.com/maho-labs/wallet",
      demo: "https://x.com/maholabs"
    },
    {
      name: "0xFrame",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/0xFrame",
      demo: "https://devfolio.co/projects/xframe-0904"
    },
    {
      name: "docGPT",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/docGPT"
    },
    {
      name: "SafeMail",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/safemail",
      demo: "https://ethglobal.com/showcase/safemail-n8b96"
    },
    {
      name: "CoB",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/cob",
      demo: "https://ethglobal.com/showcase/cob-nezxs"
    },
    {
      name: "Monerium Pay Hook",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/monerium-pay-hook",
      demo: "https://taikai.network/ethlisbon/hackathons/ethlisbon-2023/projects/clokr3nqj06pcw00136tdd29r/idea"
    },
    {
      name: "UniV4 CCLP",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/v4-cclp",
      demo: "https://ethglobal.com/showcase/univ4-cclp-hook-zd3st"
    },
    {
      name: "Offline Wallet",
      description: "A GPT-3 powered tool to generate documents",
      link: "https://github.com/cesarhuret/offline-wallet",
      demo: "https://ethglobal.com/showcase/offline-wallet-fsgce"
    },

  ]
  return (
    <div className="App">
      <div className="App-header" style={{ marginTop: 40 }}>

        <img src="/pfp.jpg" alt="logo" style={{ width: 300, height: 300, objectFit: "cover", borderRadius: "50%"   }} />
      </div>

      <header className="App-header">
        <h1 style={{ margin: 0 }}>cesar huret (✨, 💳)</h1>
        <p fontWeight={'bold'} style={{ margin: 0 }}>web3 & wizardry</p>
        <a style={{ margin: 0, color: 'white', textDecoration: 'none' }}  href="https://x.com/cesarhuret">
        
        <img style={{ margin: 0, color: 'white', textDecoration: 'underline', height: 20, paddingTop: 10 }} src="/x.svg" />
        </a>

      </header>
      <div className="App-header" style={{ justifyContent: 'start', gap: '10px', marginBottom: 40 }}>

        {
          projects.map((project) => (
            <div style={{ display: "flex", flexDirection: "row", minWidth: 350, justifyContent: 'space-between', gap: 20, backgroundColor: "#000", paddingInline: 20, paddingBlock: 10, fontSize: '24px', fontWeight: '500', borderRadius: 10, border: '0px solid #303030' }}>
              {project.name}

              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>

                <a style={{ textDecoration: 'none', cursor: 'pointer', padding: 0, display: 'flex', width: 30, borderRadius: 10, border: 'none', backgroundColor: '#000' }}
                  href={project.link}
                >
                  <img src={"/git.svg"} width={30} alt="logo" />
                </a>
                {project.demo &&
                  <a style={{ textDecoration: 'none', cursor: 'pointer', padding: 0, display: 'flex', width: 30, borderRadius: 10, border: 'none', backgroundColor: '#000', alignItems: 'center' }}
                    href={project.demo}
                  >
                    <img src={project.demo.includes("x.com") ? "/x.svg" :  project.demo.includes("ethglobal") ? "/ethglobal.svg" : project.demo.includes("taikai") ? "/taikai.jpeg" : project.demo.includes("devfolio") ? "/devfolio.svg" : '/git.svg'} height={30} width={30} alt="logo" style={{ borderRadius: 5 }} />
                  </a>
                }
              </div>

            </div>

          ))
        }


      </div>
    </div>
  );
}

export default App;
